import Companies from "./pages/companies/companies";

const dictionary = {
    en: {
        Relations: 'Relations',
        FullName: 'Name',
        Gender: 'Gender',
        Age: 'Age',
        City: 'City',
        AddCompany: 'Add company',
        AddNewsletterCampaign: 'Add campaign',
        Email: 'Email',
        Address: 'Adres',
        Telephone: 'Telephone',
        Company: 'Company',
        Postalcode: 'Postalcode',
        FirstName: 'Firstname',
        LastName: 'Lastname',
        Birthday: 'Birthday',
        AddressNr: 'AddressNr',
        FirstNameRequired: 'Firstname is required',
        LastnameRequired: 'Lastname is required',
        BirthdayRequired: 'Birthday is required',
        GenderRequired: 'Gender is required',
        EmailRequired: 'Email is required',
        EmailInvalid: 'Email is invalid',
        TelephoneRequired: 'Telephone number is required',
        AddressRequired: 'Address is required',
        AddressNrRequired: 'AddressNr is required',
        CityRequired: 'City is required',
        PostalcodeRequired: 'Postalcode is required',
        CurrentWeek: 'Current Week',
        Goal: 'Goal',
        Category: 'Category',
        ActiveDossier: 'Active Dossier',
        WeightChart: 'Progress chart',
        IntakeForm: 'Intake Form',
        Close: 'Close',
        ActiveDossierNotFound: 'Active Personal File not found!',
        Weight: 'Weight',
        Dossier: 'Dossier',
        Notes: 'Notes',
        Measurements: 'Measurements',
        Dietplan: 'Dietplan',
        ClientEvaluation: 'Weekly progress',
        IsActive: 'Is active',
        StartPeriod: 'Start period',
        DieetPeriod: 'Dieet period',
        EndPeriod: 'End period',
        NutritionCategory: 'Nutrition category',
        Actions: 'Actions',
        CreatedAt: 'Created at',
        Subject: 'Subject',
        Description: 'Description',
        MeasurementDate: 'Measurement date',
        Muscle: 'Muscle',
        Bodyfat: 'Bodyfat',
        Visceraal: 'Visceraal',
        Chest: 'Chest',
        Waist: 'Waist',
        Length: 'Length',
        Bmi: 'BMI',
        Bmr: 'BMR',
        Pal: 'PAL',
        DailyEnergyIntake: 'Daily intake',
        IntakeAdvice: 'Intake advice',
        CopyDayToDays: 'Copy from day to days',
        CopyDietToClient: 'Copy diet to client',
        Monday: 'Monday',
        Tuesday: 'Tuesday',
        Wednesday: 'Wednesday',
        Thursday: 'Thursday',
        Friday: 'Friday',
        Saturday: 'Saturday',
        Sunday: 'Sunday',
        Day: 'Day',
        EatMoment: 'Eat moment',
        Quantity: 'Quantity',
        DietWeek: 'Diet week',
        Recipes: 'Recipes',
        AddRecipe: 'Add Recipe',
        WeekNumber: 'Week number',
        SeePDF: 'See  PDF',
        WeekNrRequired: 'Week number is required!',
        NameRequired: 'Name is required!',
        FieldRequired: 'This field is required!',
        DayOfWeekRequired: 'Day is required!',
        NutritionCategoryRequired: 'Nutrition category is required!',
        TodayIsWeek: 'Today is week',
        Send: 'Send',
        SendTo: 'Send To',
        TryToSend: 'Try to send',
        SendAt: 'Send at',
        Resend: 'Resend',
        Newsletter: 'Newsletter',
        NoTaskSelected: 'No item is selected!',
        SuccessUnsubscribed: 'You are unsubscribed from the newsletter',
        SuccessSubscribed: 'You are subscribed for the newsletter',
        Unsubscribe: 'Unsubscribe',
        Subscribe: 'Subscribe',
        NewsletterWeekly: 'Weekly newsletters',
        EmailTemplates: 'Email templates',
        NewsletterCampaign: 'Newsletter Campaign',
        FindAndReplace: 'Find And Replace',
        Weeks: 'Weeks',
        Tasks: 'Tasks',
        Task: 'Task',
        Relation: 'Relation',
        Priority: 'Priority',
        DeadLine: 'Dead line',
        Companies: 'Companies',
        CompanyLogo: 'Company logo',
        UseCompanyLayout: 'Use company layout',
        CompanyColor: 'Company color',
        UsePrepMeals: 'Prep meals',
        Skinfold: 'SkinFold',
        Biceps: 'Biceps',
        Triceps: 'Triceps',
        Back: 'Back',
        Belly: 'Belly',
    },
    nl: {
        Relations: 'Relaties',
        FullName: 'Naam',
        Gender: 'Gender',
        Age: 'Leeftijd',
        AddCompany: 'Bedrijf toevoegen',
        AddNewsletterCampaign: 'Email campagne toevoegen',
        Email: 'Email',
        Address: 'Adres',
        Telephone: 'Telefoon nummer',
        Company: 'Bedrijf',
        Postalcode: 'Postcode',
        City: 'Woonplaats',
        FirstName: 'Voornaam',
        LastName: 'Achternaam',
        Birthday: 'Geboortedatum',
        AddressNr: 'Huisnummer',
        FirstNameRequired: 'Voornaam is verplicht',
        LastnameRequired: 'Achternaam is verplicht',
        BirthdayRequired: 'Geboortedatum is verplicht',
        GenderRequired: 'Gender is verplicht',
        EmailRequired: 'Email is verplicht',
        EmailInvalid: 'Email is ongeldig',
        TelephoneRequired: 'Telefoon nummer is verplicht',
        AddressRequired: 'Adres is verplicht',
        AddressNrRequired: 'Huisnummer is verplicht',
        CityRequired: 'Woonplaats is verplicht',
        PostalcodeRequired: 'Postcode is verplicht',
        CurrentWeek: 'Huidige week',
        Goal: 'Doel',
        Category: 'Categorie',
        ActiveDossier: 'Active Dossier',
        WeightChart: 'Voortgangs grafiek',
        IntakeForm: 'Intake formulier',
        Close: 'Sluiten',
        ActiveDossierNotFound: 'Active dossier niet gevonden!',
        Weight: 'Gewicht',
        Bodyfat: 'Lichaamsvet',
        Dossier: 'Dossier',
        Notes: 'Notities',
        Measurements: 'Meetingen',
        Dietplan: 'Voedingsschema',
        ClientEvaluation: 'Wekelijkse voortgang',
        IsActive: 'Is active',
        StartPeriod: 'Start periode',
        DieetPeriod: 'Dieet periode',
        EndPeriod: 'Eind period',
        NutritionCategory: 'Voedingscategorie',
        Actions: 'Acties',
        CreatedAt: 'Aangemaakt op',
        Subject: 'Onderwerp',
        Description: 'Omschrijving',
        MeasurementDate: 'Meeting datum',
        Muscle: 'Spier',
        Visceraal: 'Visceraal',
        Chest: 'Borst',
        Waist: 'Heupen',
        Length: 'Lengte',
        Bmi: 'BMI',
        Bmr: 'BMR',
        Pal: 'PAL',
        DailyEnergyIntake: 'Behoefte',
        IntakeAdvice: 'Advies',
        CopyDayToDays: 'Kopiëren dag naar dagen',
        CopyDietToClient: 'Kopiëren dieet naar client',
        Monday: 'Maandag',
        Tuesday: 'Dinsdag',
        Wednesday: 'Woensdag',
        Thursday: 'Donderdag',
        Friday: 'Vrijdag',
        Saturday: 'Zaterdag',
        Sunday: 'Zondag',
        Day: 'Dag',
        EatMoment: 'Eet moment',
        Quantity: 'Hoeveelheid',
        DietWeek: 'Dieet week',
        Recipes: 'Recepten',
        AddRecipe: 'Recepten toevoegen',
        WeekNumber: 'Week nummer',
        SeePDF: 'PDF  bekijken',
        WeekNrRequired: 'Week nummer is verplicht!',
        NameRequired: 'Naam is verplicht!',
        FieldRequired: 'Dit veld is verplicht!',
        DayOfWeekRequired: 'Dag selecteren is verplicht!',
        NutritionCategoryRequired: 'Voedingscategorie selecteren is verplicht!',
        TodayIsWeek: 'Vandaag is het week:',
        Send: 'Versturen',
        SendTo: 'Verstuurd naar',
        TryToSend: 'Start verturen',
        SendAt: 'Verstuurd op',
        Resend: 'Opnieuw versturen',
        Newsletter: 'Nieuwsbrief',
        NoTaskSelected: 'Geen item is geselecteerd!',
        SuccessUnsubscribed: 'U bent afgemeld voor de nieuwsbrief',
        SuccessSubscribed: 'U bent aangemeld voor de nieuwsbrief',
        Unsubscribe: 'Afmelden',
        Subscribe: 'Aanmelden',
        NewsletterWeekly: 'Wekenlijkse nieuwsbrief',
        EmailTemplates: 'Email templates',
        NewsletterCampaign: 'Nieuwsbrief campagne',
        FindAndReplace: 'Zoek En Vervang',
        Weeks: 'Weken',
        Tasks: 'Taken',
        Task: 'Taak',
        Relation: 'Relatie',
        Priority: 'Urgentie',
        DeadLine : 'Dead line',
        Companies: 'Bedrijven',
        CompanyLogo: 'Bedrijfs Logo',
        UseCompanyLayout: 'Gebruik bedrijfs layout',
        CompanyColor: 'Bedrijfs kleur',
        UsePrepMeals: 'Prep maaltijden',
        Skinfold: 'Huidplooi',
        Biceps: 'Biceps',
        Triceps: 'Triceps',
        Back: 'Back',
        Belly: 'Belly',
    }
};

const locales = [
    {
        Name: 'English',
        Value: 'en',
    },
    {
        Name: 'Nederlands',
        Value: 'nl',
    }
];

export default {
    getLocales() {
        return locales;
    },
    getDictionary() {
        return dictionary;
    },
};