import React, { useCallback, useState, useEffect } from 'react';
import './relationDetails.scss';
import { useLocation } from "react-router-dom";
import DataGrid, {
    Button,
    Column,
    Pager,
    Paging,
    FilterRow,
    Editing,
    SearchPanel,
    Selection,
    Form,
    Popup,
    Lookup,
    Grouping,
    GroupPanel,
} from 'devextreme-react/data-grid';
import { Button as FormButton } from 'devextreme-react/button';
import 'devextreme-react/text-area';
import Tabs, { Item } from 'devextreme-react/tab-panel';
import { RequiredRule, RangeRule, StringLengthRule, Label} from 'devextreme-react/form';
import Guid from 'devextreme/core/guid';
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import IntakeForm from '../.././components/intake-form/InakeForm';
import ActivePersonalFileDetails from '../relation/relationActivePersonalFile';
import WeightChart from '../.././components/charts/weightLineChart';
import ScrollView from 'devextreme-react/scroll-view';
import { useAuth } from '../../contexts/auth';
import { Popup as CenterPopup, ToolbarItem } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import { DieetPeriods } from '../../Enums';
import { ActivityList } from '../../components';
import { getActivePersonalFileRequest } from '../../api/relation';
import Swimlane from '../.././components/kanban/kanban';
import { formatMessage } from 'devextreme/localization';
import Box, {
    Item as ItemBox,
} from 'devextreme-react/box';
export default function RelationDetails() {
    const { user, signOut } = useAuth();
    const location = useLocation();
    const client = location.state.data;
    const [activeDossier, setActiveDossier] = useState({});
    const [popupVisible, setPopupVisible] = useState(false);
    const [weightPopupVisible, setWeightPopupVisible] = useState(false);
    const [parentIsLoaded, setParentIsLoaded] = useState(false);
    const [activityCount, setActivityCount] = useState(0);
    useEffect(() => {
        const getActivePersonalFileData = async () => {
            await getActivePersonalFileRequest(client.Oid).then((result) => {
                if (result.data.ActivePersonalFile == null) {
                    notify(formatMessage('ActiveDossierNotFound'), 'error', 2000);
                } else {
                    result.data.ActivePersonalFile.Oid = new Guid(result.data.ActivePersonalFile.Oid);
                    setActiveDossier(prevState => {
                        return { ...prevState, ...result.data.ActivePersonalFile }
                    });
                    setParentIsLoaded(true);
                }
            }).catch(error => {
                notify(error.message, 'error', 2000);
            });
        }
        getActivePersonalFileData();
        activityDataSource.load();
    }, [client.Oid]);

    const dataSource = new DataSource({
        store: new ODataStore({
            version: 4,
            url: `${process.env.REACT_APP_URL}/api/odata/PersonalFile`,
            key: 'Oid',
            keyType: 'Guid',
            errorHandler: (error) => {
                if (error.httpStatus === 401)
                {
                    signOut(true);
                }
            },
            beforeSend: function (e) {
                if (e.method === "POST") {
                    e.payload["RelationOid"] = String(client.Oid);
                }
                e.headers = {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                };
            },
            onInserted: function (values, key) {
                if (values.IsActive === true)
                {
                    setActiveDossier(prevState => {
                        return { ...prevState, ...values }
                    });
                }
            },
            onInserting: function (values, key) {
                if (values.NutritionCategory) {
                    values["NutritionCategoryOid"] = values.NutritionCategory.Oid.toString();
                    delete values.NutritionCategory;
                }
                if (values.PersonalGoal) {
                    values["PersonalGoalOid"] = values.PersonalGoal.Oid.toString();
                    delete values.PersonalGoal;
                }
            },
            onUpdating: function (key, values) {
                if (values.NutritionCategory) {
                    values["NutritionCategoryOid"] = values.NutritionCategory.Oid.toString();
                }
                if (values.PersonalGoal) {
                    values["PersonalGoalOid"] = values.PersonalGoal.Oid.toString();
                }
            }
        }),
        select: [
            'Oid',
            'PersonalGoal',
            'StartPeriod',
            'DieetPeriod',
            'EndPeriod',
            'IsActive',
            'NutritionCategory',
            'Relation',
            'RelationOid',
            'UsePrepTheFood',
        ],
        expand: ['ProgressItems', 'Relation', 'NutritionCategory', 'PersonalGoal'],
        filter: ['Relation/Oid', '=', new Guid(client.Oid)]
    });

    const dataSourceMeasurements = {
        store: {
            type: 'odata',
            version: 4,
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/ProgressItem`,
            beforeSend: function (e) {
                e.headers = {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                };
            },
            onInserting: function (values, key) {
                values["PersonalFileOid"] = activeDossier.Oid._value.toString(); 
            },
        },
        select: [
            'Oid',
            'MeasurementDate',
            'Weight',
            'Bodyfat',
            'Waist',
            'Skinfold',
            'Muscle',
            'Visceraal',
            'Chest',
            'Waist',
            'Length',
            'Bmi',
            'Bmr',
            'Pal',
            'Belly',
            'Back',
            'Triceps',
            'Biceps',
            'DailyEnergyIntake',
            'IntakeAdvice'
        ],
        expand: ['PersonalFile'],
        filter: ['PersonalFile/Oid', '=', activeDossier.Oid],
    };

    const dataSourceNotes = {
        store: {
            type: 'odata',
            version: 4,
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Note`,
            beforeSend: function (e) {
                e.headers = {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                };
            },
            onInserting: function (values, key) {
                values["PersonalFileOid"] = activeDossier.Oid._value.toString();
            },
        },
        select: [
            'Oid',
            'Subject',
            'Description',
            'CreatedAt',
            'UpdatedAt',
            'PersonalFile'
        ],
        expand: ['PersonalFile'],
        filter: ['PersonalFile/Oid', '=', activeDossier.Oid],
    };

    const dataSourceWeeklyProgress = {
        store: {
            type: 'odata',
            version: 4,
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/WeeklyProgressForm`,
            beforeSend: function (e) {
                e.headers = {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                };
            },
            onInserting: function (values, key) {
                console.log(activeDossier);
                values["PersonalFileOid"] = activeDossier.Oid._value.toString();
            },
        },
        select: [
            'Oid',
            'WeekNumber',
            'Question1_1',
            'Question1_2',
            'Question1_3',
            'Question1_4',
            'Question1_5',
            'Question1_6',
            'Question1_7',
            'PersonalFile'
        ],
        expand: ['PersonalFile'],
        filter: ['PersonalFile/Oid', '=', activeDossier.Oid],
    };

    const nutritionCategoryData = {
        store: {
            type: 'odata',
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/NutritionCategory`,
            version: 4,
            beforeSend: function (e) {
                e.headers = { 'Authorization': `Bearer ${localStorage.getItem("token")}` };
            },
        }
    };

    const personalGoalData = {
        store: {
            type: 'odata',
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/PersonalGoal`,
            version: 4,
            beforeSend: function (e) {
                e.headers = { 'Authorization': `Bearer ${localStorage.getItem("token")}` };
            },
        }
    };

    const activityDataSource = new DataSource({
        store: {
            type: 'odata',
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Activity`,
            version: 4,
            beforeSend: function (e) {
                e.headers = { 'Authorization': `Bearer ${localStorage.getItem("token")}` };
            },
            onLoaded: function (result) {
                const openActivitys = result.filter((d) => d.Status === 0 || d.Status === 1).length;
                setActivityCount(openActivitys);
            },
            onInserting: function (values, key) {
                    values["RelationOid"] = client.Oid;
            }
        },
        expand: ['Relation'],
        filter: ['Relation/Oid', '=', new Guid(client.Oid)]
    });

    const showInfo = (employee) => {
        if (popupVisible)
        {
            setPopupVisible(false);
        }
        setPopupVisible(true);
    }

    const showWeightPopup = (e) => {
        if (weightPopupVisible) {
            setWeightPopupVisible(false);
        }
        setWeightPopupVisible(true);
    }

    const hideInfo = () => {
        setPopupVisible(false);
        setWeightPopupVisible(false);
    }

    const weightHidePopup = () => {
        setWeightPopupVisible(false);
    }

    const closeButtonOptions = {
        text: formatMessage('Close'),
        onClick: hideInfo
     
    };

    const onSelectDossierChanged = useCallback((e) =>
    {
            e.changes.forEach(change => {
               
                if (change.data.IsActive) {
                
                    e.component.byKey(e.changes[0].key._value).done(function (dataObject) {
                        setActiveDossier(prevState => {
                            return { ...prevState, ...dataObject };

                        });
                    }).fail(function (error) {
                        // handle error
                    }); 
                    
                }
            });
    }, []);

    const renderDossier = () => {
        return <div>
            <DataGrid
                className={'dx-card wide-card'}
                dataSource={dataSource}
                onSaved={onSelectDossierChanged}
                autoNavigateToFocusedRow={true}
                focusedRowEnabled={true}
                focusedRowKey={0}
                showBorders={false}
                columnAutoWidth={true}
                columnHidingEnabled={true}>
                <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true}>
                </Editing>
                <Selection mode="single" />
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />
                <SearchPanel width={500} visible={true} />
                <Column width={100} dataField={'IsActive'} caption={formatMessage('IsActive')} dataType="boolean" />
                <Column dataField={'StartPeriod'} caption={formatMessage('StartPeriod')} format="dd-MM-yyyy"  dataType="date" >
                    <RequiredRule message="StartPeriod is required" />
                </Column>
                <Column dataField={'DieetPeriod'} caption={formatMessage('DieetPeriod')} >
                    <Lookup
                        dataSource={DieetPeriods}
                        displayExpr={'Name'}
                        valueExpr={'Value'} >
                    </Lookup>
                    <RequiredRule message="DieetPeriods is required" />
                </Column>
                <Column dataField={'EndPeriod'} caption={formatMessage('EndPeriod')} format="dd-MM-yyyy" allowEditing={false} dataType="date" />
                <Column
                    dataField={'PersonalGoal.Oid'}
                    caption={formatMessage('Goal')}>
                    <Lookup
                        dataSource={personalGoalData}
                        valueExpr={'Oid'}
                        displayExpr={'Name'}
                    />
                    <RequiredRule message="PersonalGoal is required" />
                </Column>
                <Column
                    dataField={'NutritionCategory.Oid'}
                    caption={formatMessage('NutritionCategory')}>
                    <Lookup
                        dataSource={nutritionCategoryData}
                        valueExpr={'Oid'}
                        displayExpr={'Name'}/>
                    <RequiredRule message="NutritionCategory is required" />
                </Column>
                <Column width={120} dataField={'UsePrepTheFood'} caption={formatMessage('UsePrepMeals')} dataType="boolean" />
                <Column type="buttons" caption={formatMessage('Actions')} width={110}>
                    <Button name="delete" />
                    {/* <Button icon="lock" hint="Active Dossier" name="Active Dossier" onClick={onLockDossier} /> */} 
                </Column>
            </DataGrid>
        </div>;
    };

    const renderNotes = () => {
        return <div>
            <DataGrid
                className={'dx-card wide-card'}
                dataSource={dataSourceNotes}
                showBorders={false}
                columnAutoWidth={true}
                columnHidingEnabled={true}>
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true}>
                    <Popup title={formatMessage('Notes')} showTitle={true}  />
                    <Form>
                        <Item itemType="group" colSpan={2}>
                            <Item dataField="Subject" />
                        </Item>
                        <Item
                            dataField="Description"
                            editorType="dxTextArea"
                            colSpan={2}
                            editorOptions={{ height: 500 }} />
                    </Form>
                </Editing>
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />
                <SearchPanel width={500} visible={true} />
                <Column width={200} dataField={'CreatedAt'} format="dd-MM-yyyy" caption={formatMessage('CreatedAt')} />
                <Column width={500} dataField={'Subject'} caption={formatMessage('Subject')} />
                <Column dataField={'Description'} caption={formatMessage('Description')} />
            </DataGrid>
        </div>;
    };

    const renderMeasurements = () => { 
        return <div>
            <DataGrid
                className={'dx-card wide-card'}
                dataSource={dataSourceMeasurements}
                showBorders={false}
                columnAutoWidth={true}
                columnHidingEnabled={true}>
                <Editing
                    mode="form"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true}>
                </Editing>
                <Selection mode="single" />
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />
                <Column dataField={'MeasurementDate'} caption={formatMessage('MeasurementDate')} format="dd-MM-yyyy" dataType="date" />
                <Column dataField={'Length'} caption={formatMessage('Length') + " (cm)"} >
                    <RangeRule message="Length moet minimaal 1 zijn" min={1.0}  />
                </Column>
                <Column dataField={'Weight'} caption={formatMessage('Weight') + " (kg)"}>
                    <RangeRule message="Weight moet minimaal 1 zijn" min={1.0} />
                </Column>
                <Column dataField={'Bodyfat'} caption={formatMessage('Bodyfat') + " (%)"} />
                <Column dataField={'Muscle'} caption={formatMessage('Muscle')} />
                <Column dataField={'Visceraal'} caption={formatMessage('Visceraal')} />
                <Column dataField={'Chest'} caption={formatMessage('Chest') + " (cm)"} />
                <Column dataField={'Waist'} caption={formatMessage('Waist') + " (cm)"} />
                <Column dataField={'Biceps'} caption={formatMessage('Biceps') + " (cm)"} />
                <Column dataField={'Triceps'} caption={formatMessage('Triceps') + " (cm)"} />
                <Column dataField={'Back'} caption={formatMessage('Back') + " (cm)"} />
                <Column dataField={'Belly'} caption={formatMessage('Belly') + " (cm)"} />
                <Column dataField={'Skinfold'} caption={formatMessage('Skinfold')} />
                <Column dataField={'Pal'} caption={formatMessage('Pal')} >
                    <RangeRule message="Pal waarde is buiten bereik van 1 en 2.2" min={1.0} max={2.2} />
                </Column>
                <Column dataField={'Bmi'} caption={formatMessage('Bmi')} allowEditing={false}  />
                <Column dataField={'Bmr'} caption={formatMessage('Bmr')}  allowEditing={false} />
                <Column dataField={'DailyEnergyIntake'} caption={formatMessage('DailyEnergyIntake')} />
                <Column dataField={'IntakeAdvice'} caption={formatMessage('IntakeAdvice')} />
            </DataGrid>
        </div>
    };

    const weeklyProgressFormEditorOptions = { height: 150 };
    const renderWeeklyProgressForm = () => {
        return <div>
            <DataGrid
                className={'dx-card wide-card'}
                dataSource={dataSourceWeeklyProgress}
                showBorders={false}
                columnAutoWidth={true}
                columnHidingEnabled={true}>
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true}>
                    <Popup title={formatMessage('ClientEvaluation')} width={700} height={850} showTitle={true} />
                    <Form>
                        <Item dataField="WeekNumber" colSpan={2} />
                        <Item editorOptions={weeklyProgressFormEditorOptions} editorType="dxTextArea" dataField="Question1_1" colSpan={2}>
                            <Label text="Hoe is het afgelopen week gegaan met het eetpatroon?" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </Item>
                        <Item editorOptions={weeklyProgressFormEditorOptions} editorType="dxTextArea" dataField="Question1_2" colSpan={2}>
                            <Label text="Beweging - Hoeveel heb je afgelopen week bewogen en gesport?" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </Item>
                        <Item editorOptions={weeklyProgressFormEditorOptions} editorType="dxTextArea" dataField="Question1_3" colSpan={2}>
                            <Label text="Slaap - Hoe heb je geslapen?" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </Item>
                        <Item editorOptions={weeklyProgressFormEditorOptions} editorType="dxTextArea" dataField="Question1_4" colSpan={2}>
                            <Label text="Ontspanning - Op wat voor manieren heb je tijd voor jezelf genomen?" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </Item>
                        <Item editorOptions={weeklyProgressFormEditorOptions} editorType="dxTextArea" dataField="Question1_5" colSpan={2}>
                            <Label text="Stoelgang - Heb je veranderingen gemerkt in je stoelgang? Zo ja, wat is er veranderd?" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </Item>
                        <Item editorOptions={weeklyProgressFormEditorOptions} editorType="dxTextArea" dataField="Question1_6" colSpan={2}>
                            <Label text="Welk cijfer geef je afgelopen week in het algemeen?" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </Item>
                        <Item editorOptions={weeklyProgressFormEditorOptions} editorType="dxTextArea" dataField="Question1_7" colSpan={2}>
                            <Label text="Welke punten ga je aankomende week verbeteren?" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </Item>
                    </Form>
                </Editing>
                <GroupPanel visible={false} />
                <Grouping autoExpandAll={true} />
                <FilterRow visible={true} />
                <Column dataField={'Question1_1'} visible={false} />
                <Column dataField={'Question1_2'} visible={false} />
                <Column dataField={'Question1_3'} visible={false} />
                <Column dataField={'Question1_4'} visible={false} />
                <Column dataField={'Question1_5'} visible={false} />
                <Column dataField={'Question1_6'} visible={false} />
                <Column dataField={'Question1_7'} visible={false} />
                <Column dataField={'WeekNumber'} caption={formatMessage('DietWeek')} alignment="Left" sortOrder="asc" />
            </DataGrid>
        </div>;
    };
    return (
       
        <React.Fragment>
            <h2 className={"content-block"}>Profile</h2>
            <div className={"content-block dx-card responsive-paddings"}>
                <Box direction="col" width="100%" height={200}>
                    <ItemBox ratio={2} baseSize={0}>
                        <Box direction="row" width={400} height={125}>
                            <ItemBox ratio={2}>
                                <div>{formatMessage('FullName')}: {client.FullName}</div>
                                <div>{formatMessage('Age')}: {client.Age}</div>
                                <ActivePersonalFileDetails parentIsLoaded={parentIsLoaded} activeDossier={activeDossier} />
                            </ItemBox>
                        </Box>
                    </ItemBox>
                    <ItemBox ratio={1}>
                        <div className="rect demo-dark footer">
                            <div>
                                <FormButton
                                    text={formatMessage('WeightChart')}
                                    icon='chart'
                                    onClick={showWeightPopup} />
                                <FormButton icon='textdocument' text={formatMessage('IntakeForm')} onClick={showInfo} />
                                <CenterPopup
                                    visible={popupVisible}
                                    onHiding={hideInfo}
                                    dragEnabled={false}
                                    hideOnOutsideClick={true}
                                    showCloseButton={false}
                                    showTitle={true}
                                    title="Intake Form"
                                    width={1000}
                                    height={800} >
                                    <ToolbarItem
                                        widget="dxButton"
                                        toolbar="top"
                                        location="after"
                                        options={closeButtonOptions} />
                                    <ScrollView width="100%" height="100%">
                                        <IntakeForm relationOid={client.Oid} />
                                    </ScrollView>
                                </CenterPopup>
                                <CenterPopup
                                    visible={weightPopupVisible}
                                    onHiding={weightHidePopup}
                                    dragEnabled={false}
                                    hideOnOutsideClick={true}
                                    showCloseButton={false}
                                    showTitle={true}
                                    title={formatMessage('WeightChart')}
                                    width={1200}
                                    height={500} >
                                    <ToolbarItem
                                        widget="dxButton"
                                        toolbar="top"
                                        location="after"
                                        options={closeButtonOptions} />
                                    <ScrollView width="100%" height="100%">
                                        <WeightChart parentIsLoaded={parentIsLoaded} personalFileOid={activeDossier.Oid} />
                                    </ScrollView>
                                </CenterPopup>
                            </div>
                        </div>
                    </ItemBox>
                </Box>
            </div>
            <div className={"content-block dx-card "}>
                <Tabs>
                    <Item title={formatMessage('Dossier')} render={renderDossier}></Item>
                    <Item title={formatMessage('Notes')} render={renderNotes}></Item>
                    <Item title={formatMessage('Tasks')} badge={activityCount}>
                        <ActivityList dataSource={activityDataSource} mode="popup" displayRelation={false} />
                    </Item>
                <Item title={formatMessage('Measurements')} render={renderMeasurements}></Item>
                    <Item title={formatMessage('Dietplan')} >
                        <Swimlane activeDossierOid={activeDossier.Oid}  />
                    </Item>
                    <Item title={formatMessage('ClientEvaluation')} render={renderWeeklyProgressForm}></Item>
                </Tabs>
            </div>
        </React.Fragment>

    );
}



