import React, { useCallback, useState, useEffect } from 'react';
import './profile.scss';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, {
    Selection, Paging, FilterRow, Scrolling,
} from 'devextreme-react/data-grid';
import 'devextreme/data/odata/store';
import { useAuth } from '../../contexts/auth';
import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';

export default function Profile() {
    const CALENDLY_AUTH_BASE_URL = process.env.REACT_APP_CALENDLY_AUTH_BASE_URL;
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
    const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;

    const gridColumns = ['Name'];
    const [gridBoxValue, setGridBoxValue] = useState();
    const { signOut, updateUser, getUserData } = useAuth();

    useEffect(() => {
        (async function () {
            const result = await getUserData();
            if (result.isOk) {
                setGridBoxValue(result.companies);
            }
        })();
    }, []);

    const newsletterCampaignData = {
        store: {
            type: 'odata',
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Company`,
            version: 4,
            errorHandler: (error) => {
                if (error.httpStatus === 401) {
                    signOut(true);
                }
            },
            beforeSend: function (e) {
                e.headers = { 'Authorization': `Bearer ${localStorage.getItem("token")}` };
            },
        },
        select: [
            'Oid',
            'Name',
        ]
    };
    const dataGridRender = useCallback(
        () => (
            <DataGrid
                height={345}
                dataSource={newsletterCampaignData}
                columns={gridColumns}
                hoverStateEnabled={true}
                selectedRowKeys={gridBoxValue}
                onSelectionChanged={dataGridOnSelectionChanged} >
                <Selection mode="multiple" />
                <Scrolling mode="virtual" />
                <Paging
                    enabled={true}
                    pageSize={10}
                />
                <FilterRow visible={true} />
            </DataGrid>
        ),
        [newsletterCampaignData, gridBoxValue],
    );
    const syncDataGridSelection = useCallback((e) => {
        setGridBoxValue(e.value || []);
    }, []);

    const dataGridOnSelectionChanged = useCallback((e) => {
        setGridBoxValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);
    }, []);

    const onClick = (e) => {
        updateUser(gridBoxValue).then((res) => {
            notify(res, 'success', 2000);
        }).catch((e) =>
        {
            notify(e, 'error', 2000);
        });
    };

    const onClickConnectToCalendly = (e) => {
        const authorizationUrl = `${CALENDLY_AUTH_BASE_URL}/oauth/authorize?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}`;
        window.location.href = authorizationUrl; 
    };

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Profile</h2>

      <div className={'content-block dx-card responsive-paddings'}>
        <div className={'form-avatar'}>
          <img
            alt={''}
            src={`https://cydportal.nl/Images/profileImage.png`}
          />
        </div>
              <span>
                  <Button
                      width={200}
                      text="Connect to Calendly"
                      type="success"
                      stylingMode="contained"
                      onClick={onClickConnectToCalendly}
                  />
              </span>
      </div>

      <div className={'content-block dx-card responsive-paddings'}>
              <div className="dx-field">
                  <div className="dx-field-label">Toegang to bedrijven</div>
                  <div className="dx-field-value">
                      <DropDownBox
                          value={gridBoxValue}
                          valueExpr="Oid"
                          deferRendering={false}
                          displayExpr="Name"
                          placeholder="Select a value..."
                          showClearButton={true}
                          dataSource={newsletterCampaignData}
                          onValueChanged={syncDataGridSelection}
                          contentRender={dataGridRender}
                      />
                  </div>
              </div>
              <div className="buttons-column">
                  <div>
                      <Button
                          width={120}
                          text="Save"
                          type="success"
                          stylingMode="contained"
                          onClick={onClick}
                      />
                  </div>
              </div>
          </div>
    </React.Fragment>
  );
}
