import React, { useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import { copyClientDiet } from '../../api/dietItemPlan';
import SelectBox from 'devextreme-react/select-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, {
    Selection, Paging, FilterRow, Scrolling, Column,
    HeaderFilter
} from 'devextreme-react/data-grid';
import { DietVariation } from '../../Enums';
import { formatMessage } from 'devextreme/localization';
const CopyClientDietDialog = forwardRef(({ clientPersonalFileOid, dietVariation, onComplete}, ref) => {

    const { user, signOut } = useAuth();
    const [fromDietVariation, setDietVariation] = useState('DietVariation1');
    const dietVariationSelectBox = useRef(null);
    const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);
    const [selectedPerson, setPerson] = useState(null);

    const relationData = {
        store: {
            type: 'odata',
            version: 4,
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Relation`,
            errorHandler: (error) => {
                if (error.httpStatus === 401) {
                    signOut(true);
                }
            },
            beforeSend: function (e) {
                e.headers = {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                };

            },
        },
        select: [
            'Oid',
            'FullName',
            'ActivePersonalFile',
        ],
        expand: ['ActivePersonalFile(expand=PersonalGoal,NutritionCategory,ProgressItems($orderby=MeasurementDate desc))'],
        filter: ['ActivePersonalFile', '<>', null],
        sort: [
            { getter: "FirstName", desc: false }
        ]
    };

    useImperativeHandle(ref, () => ({
        clearForm: () => clearForm(),
        onComplete: onComplete
    }));

    const clearForm = () => {
        setPerson(null);
        setDietVariation('DietVariation1');
        dietVariationSelectBox.current.instance.reset();
    };

    const onSubmit = async (e)  => {
        if (selectedPerson != null) {
            await copyClientDiet(selectedPerson.ActivePersonalFile.Oid, clientPersonalFileOid, fromDietVariation, dietVariation).then((result) => {
                if (result.status === 201) {
                    notify(result.data, 'success', 2000);
                    onComplete();
                }
            }).catch(error => {
                const errorMessage = error.response?.data || "An error occurred. Please try again.";
                notify(errorMessage, 'error', 2000);
            });
        } else
        {
            notify("Select an client first...", 'error', 2000);
        }
    };

    const selectedDietVariationhanged = useCallback((e) => {
        setDietVariation(e.value?.Value);
    }, [fromDietVariation]);

    const dataGridOnSelectionChanged = useCallback((e) => {
        if(e.selectedRowsData)
        {
            setPerson(e.selectedRowsData[0]);
            setIsGridBoxOpened(false);
        }

      }, []);

    const dataGridRender = useCallback(
        () => (
            <DataGrid
                height={345}
                key="Oid"
                dataSource={relationData}
                hoverStateEnabled={true}
                onSelectionChanged={dataGridOnSelectionChanged} >
                <Selection mode="single" />
                <Scrolling mode="virtual" />
                <Paging
                    enabled={true}
                    pageSize={10}
                />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                <Column dataField={'FullName'} caption={formatMessage('FullName')}  />
                <Column width={110} dataField={'ActivePersonalFile.PersonalGoal.Name'} caption={formatMessage('Goal')}  />
                <Column width={110} dataField={'ActivePersonalFile.NutritionCategory.Name'} caption={formatMessage('Category')}  />
                <Column width={170} dataField={'ActivePersonalFile.ProgressItems[0].IntakeAdvice'} caption={formatMessage('IntakeAdvice')+" kcal"}  />
                <Column width={170} dataField={'ActivePersonalFile.ProgressItems[0].DailyEnergyIntake'} caption={formatMessage('DailyEnergyIntake')+" kcal"}  />
            </DataGrid>
        ),
        [selectedPerson,dataGridOnSelectionChanged,relationData],
    );

    const onGridBoxOpened = useCallback((e) => {
        if (e.name === 'opened') {
          setIsGridBoxOpened(e.value);
        }
      }, []);

    return (
        <React.Fragment>
            <div className={'content-block'}>
                <div style={{ paddingBottom: 10 }} >
                    From:
                    <SelectBox
                        ref={dietVariationSelectBox}
                        width="350"
                        displayExpr="Name"
                        items={DietVariation}
                        defaultValue={DietVariation[0]}
                        onValueChanged={selectedDietVariationhanged} />
                </div>
                <DropDownBox
                         value={selectedPerson?.Oid?._value} 
                          opened={isGridBoxOpened}
                          valueExpr="Oid"
                          deferRendering={false}
                          displayExpr={"FullName"}
                          placeholder="Select a value..."
                          showClearButton={true}
                          dataSource={relationData}
                          onOptionChanged={onGridBoxOpened}
                          contentRender={dataGridRender}
                      />
                <div>
                    {
                        (selectedPerson == null) ? (
                            "Select an client first..."
                        ) :
                            (
                                <div style={{ position: 'absolute',padding: 10 }} >
                                    <div>Goal: {selectedPerson.ActivePersonalFile.PersonalGoal.Name}</div>
                                    <div>Category: {selectedPerson.ActivePersonalFile.NutritionCategory.Name}</div>
                                    <div>{formatMessage('IntakeAdvice')} : {selectedPerson.ActivePersonalFile.ProgressItems[0]?.IntakeAdvice} kcal</div>
                                    <div>{formatMessage('DailyEnergyIntake')} : {selectedPerson.ActivePersonalFile.ProgressItems[0]?.DailyEnergyIntake} kcal</div>
                                </div>
                            )
                    }
                </div>
                <div style={{ position: 'absolute', bottom: 0, right: 0, padding: 10}}>
                    <Button
                        icon="save"
                        text='Apply'
                        onClick={onSubmit}
                        type="success" />
                </div>
            </div>
         
        </React.Fragment>
    );
});
export default CopyClientDietDialog;
